import $ from 'jquery';
import {load} from 'recaptcha-v3';
import AOS from 'aos/dist/aos';

const fadeSpeed = 300;
const $hamburger = $('#hamburger');
const $navbarOverlay = $('#navbar-overlay');
const $navbarOverlayMenuItems = $('.navbar-overlay-menu-item');
const sendButton = $('#send-mail-btn');

const emailField = $('#email-input');
const nomeField = $('#nome-input');
const textField = $('#text-input');

const emailError = $('#email-error');
const nomeError = $('#nome-error');
const textError = $('#text-error');

const success = $('#success-message')
const error = $('#error-message');
const form = $('#contattaci-form');

var isOverlay = false;

let recaptcha_token = null

AOS.init();

load('6LdvEuQZAAAAAMh8paXeLxCwGKQD78sTztVzxK3_', {
    // useRecaptchaNet: true,
    autoHideBadge: true
}).then((recaptcha) => {
    recaptcha.execute('sendEmail').then((token) => {
        recaptcha_token = token;
    }).catch(() => {
        //errore
    })
}).catch(() => {
    //errore
});

const validateEmail = function (email) {
    let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
};

const closeMenu = function () {
    $hamburger.trigger('click');
};

$(document).ready(function () {

    $hamburger.on("click", function (event) {
        $hamburger.toggleClass("is-active");

        if (!isOverlay) {
            $navbarOverlay.css("display", "flex").hide().fadeIn(fadeSpeed);
            $('body').css('overflow', 'hidden');
        } else {
            $navbarOverlay.fadeOut(fadeSpeed);
            $('body').css('overflow', 'auto');
        }
        isOverlay = !isOverlay
    });

    sendButton.on('click', function (event) {

        nomeError.hide()
        emailError.hide()
        textError.hide()

        new Promise((resolve, reject) => {
            let rej = false
            if (!nomeField.val()) {
                nomeError.show()
                rej |= true
            }

            if (!validateEmail(emailField.val())) {
                emailError.show()
                rej |= true
            }

            if (!textField.val()) {
                textError.show()
                rej |= true
            }

            if (rej) {
                reject()
            }
            resolve()
        }).then(() => {
            $.ajax({
                url: 'mailer.php',
                method: "POST",
                data: {
                    recaptcha_response: recaptcha_token,
                    nome: nomeField.val(),
                    email: emailField.val(),
                    text: textField.val()
                },
                success: function (data, state) {
                    form.hide()
                    success.show()
                },
                error: function () {
                    form.hide()
                    error.show()
                }
            });
        }).catch(() => {

        })
    });

    $navbarOverlayMenuItems.on('click', function (event) {
        closeMenu();
    })

    // $('.contact-button').on('click', function (event) {
    //     location.href = "contattaci.html";
    // })
});

(function (w, d) {
    var loader = function () {
        var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
        s.src = "https://cdn.iubenda.com/iubenda.js";
        tag.parentNode.insertBefore(s, tag);
    };
    if (w.addEventListener) {
        w.addEventListener("load", loader, false);
    } else if (w.attachEvent) {
        w.attachEvent("onload", loader);
    } else {
        w.onload = loader;
    }
})(window, document)

